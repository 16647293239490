import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Cookies = () => {
    return (
        <Layout>
            <SEO title="Sütitájékoztató" />
            <h1>Sütitájékoztató</h1>

            <div className="cookie-policy-wrapper">
                <p>Ez a süti szabályzat leírja, hogy mik azok a sütik és hogyan használja az annyiavilag.hu weboldal. („Weboldal” vagy „Szolgáltatás”) és a hozzá tartozó egyéb termékek vagy szolgáltatások (együttesen „Szolgáltatások”). Ez a Szabályzat egy jogi megállapodás Ön („felhasználó”, „Ön”) és a weboldal szolgáltatója között („Szolgáltató”, „mi”, „nekünk” és „miénk”). Kérjük úgy olvassa el a Szabályzatot, hogy megértse a sütik különböző fajtáját, amelyeket használunk, az információkat, amiket a sütik használatával gyűjtünk illetve hogy ezeket az információkat hogyan használjuk fel. Továbbá leírja azokat a választási lehetőségeket is Ön számára, miszerint Ön elfogadja vagy elutasítja a sütik használatát.</p>

                <h3>Mik azok a sütik?</h3>

                <p>A sütik a szövegfájlokban tárolt kis adatok, amelyek a számítógépre vagy más eszközökre lesznek mentve, amikor egy webhely betöltődik a böngészőben. Széles körben használják arra, hogy megjegyezzék Önt és az Ön preferenciáit, akárcsak egy egyszeri látogatás alkalmával (a „munkamenet sütin” keresztül), de akár többszöri, ismétlődő látogatások alkalmával is („állandó süti” használatával).</p>
                <p>A munkamenet sütik időszakos sütik, amiket csak addig használ a weboldal, amíg látogatják és azonnal lejárnak, amint bezárja a webböngészőt.</p>

                <p>Az állandó sütiket arra használják, hogy megjegyezzék az Ön preferenciáit a Weboldalon belül és az Ön asztali gépén vagy mobiltelefonján maradjon, még akkor is, ha Ön már bezárta a böngészőt vagy újraindította a számítógépét. A Weboldal vagy Szolgáltatás látogatása közben gondoskodnak arról, hogy egy konzisztens és hatásos élményt adjanak Önnek.
                </p>
                <p>A sütiket beállíthatja a Weboldal („elsődleges süti”), vagy egy harmadik fél is, mint például azok, akik tartalmakat, reklámokat vagy analitikát kínálnak a Weboldalon („third party cookies”). Ez a harmadik fél megismeri Önt, amikor meglátogatja a weboldalunkat, de még akkor is, amikor bizonyos más weboldalakat látogat.</p>
                <h3>Mi milyen fajta sütiket használunk?</h3>

                <p>Mi nem arra használjuk a sütiket, hogy le tudjuk nyomozni az internet vagy Weboldal használatát, gyűjteni, vagy tárolni tudjuk a személyes információit, vagy bármilyen más tevékenységét. Ugyanakkor, kérjük vegye figyelembe, hogy vannak országok, ahol a süti ID személyes információnak minősül. Bizonyos mértékig feldolgozunk olyan adatokat, amelyek személyes információnak minősülnek, ezeket a saját adatvédelmi és süti szabályzatunk szerint dolgozzuk fel.</p>
                <h3>Változások és kiegészítések</h3>

                <p>Fenntartjuk a jogot, hogy bármikor módosíthassuk ezt a házirendet vagy annak a Weboldalra és a Szolgáltatásra vonatkozó feltételeit, amelyek a jelen Irányelv frissített verziójának a Weboldalon történő közzétételével lépnek hatályba. Amint ezt megtesszük, közzétesszük a Weboldal főoldalán. A Weboldal és Szolgáltatás folytonos használata bármilyen változás esetén, az Ön beleegyezését jelenti a változásokra vonatkozóan. A Szabályzat a WebsitePolicies által lett kiállítva.
                </p>
                <h3>Ezen házirend elfogadása</h3>

                <p>Ön tudomásul veszi, hogy elolvasta jelen Szabályzatot és beleegyezett a felhasználási feltételekbe. Az Weboldal és Szolgáltatás használatával beleegyezik, hogy Önt köti ez a Szabályzat. Ha Ön nem vállalja a Szabályzat irányelveinek betartását, abban az esetben nem jogosult a Weboldal és a Szolgáltatás hozzáférésére és használatára.</p>
                <h3>Kapcsolat</h3>

                <p>Ha többet szeretne megtudni a Szabályzatról, vagy a sütikkel kapcsolatos bármilyen kérdése van, küldjön e-mailt a hello@annyiavilag.hu e-mail címre.</p>

                <p>A dokumentum legutolsó frissítése 2020. szeptember 14-én történt.</p>
            </div>
        </Layout>
    )
};

export default Cookies;